import { styled } from '@mui/material/styles';

import { theme, palette } from '../../theme';
import { Paragraph } from '../Paragraph';

export const StyledOuterWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.4rem 3rem;
    background-color: ${palette.common.white};

    ${theme.breakpoints.up('desktop')} {
        padding: 2.1rem 3rem;
    }
`;

export const StyledInnerWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    ${theme.breakpoints.up('desktop')} {
        gap: 2.4rem;
    }
`;

export const StyledContainer = styled('div')`
    display: flex;
    flex-basis: 20%;
    justify-content: center;

    ${theme.breakpoints.up('desktop')} {
        flex: 1 0 auto;
        flex-basis: unset;
        align-items: center;
    }

    svg {
        min-width: 2.4rem;
        min-height: 2.4rem;
    }
`;

export const StyledParagraph = styled(Paragraph)`
    padding-left: ${theme.spacing(5)};
    font-size: 1.2rem;
    line-height: 1.4rem;

    ${theme.breakpoints.up(769)} {
        padding-left: ${theme.spacing(10)};
        font-size: 1.4rem;
        line-height: 1.6rem;
    }
`;
