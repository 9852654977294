import { styled } from '@mui/material/styles';

import { palette, PromoColors, promoPalette, Theme } from '../../theme';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';

export const StyledPromoSnackBar = styled('div')<{
    $isSticky?: boolean;
    $paletteColor: PromoColors;
}>`
    position: ${({ $isSticky }) => ($isSticky ? 'sticky' : 'static')};
    top: 6rem;
    z-index: 2;
    display: inline-block;
    width: 100%;
    border-bottom: 0.1rem solid ${palette.primary.main}20;
    border-radius: 0.8rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        top: 7.2rem;
    }
    && {
        svg {
            g {
                fill: ${({ $paletteColor }) =>
                    promoPalette[$paletteColor].text};
            }
        }
        .promoSnackBar {
            h2 {
                color: ${({ $paletteColor }) =>
                    promoPalette[$paletteColor].text};
            }
            background-color: ${({ $paletteColor }) =>
                promoPalette[$paletteColor].background};
        }
    }
`;

export const StyledHeadingContent = styled('div')`
    padding-top: 1.2rem;
    text-align: center;
    background-color: ${palette.common.white};
`;

export const StyledParagraph = styled(Paragraph)`
    display: inline;
    margin-right: 1rem;
    margin-left: 0.6rem;

    &:last-child {
        margin-right: 0;
    }

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin-right: 2rem;
    }
`;

export const StyledLink = styled('a')`
    text-decoration: none;
`;

export const StyledHeading = styled(Heading)`
    display: inline-block;
    width: 2rem;
    color: ${palette.common.blue};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        width: 2.3rem;
    }
`;
