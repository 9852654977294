import { StoryBox } from '../StoryBox/StoryBox';
import {
    StyledContent,
    StyledContentListItem,
    StyledHeading,
    StyledWrapper,
} from './StoryBoxGroup.styled';
import { StoryBoxGroupProperties } from './StoryBoxGroup.types';

export const StoryBoxGroup = ({
    id,
    heading,
    data,
    variant = 'white',
    className,
    centred,
}: StoryBoxGroupProperties) => {
    return (
        <StyledWrapper id={id} className={className}>
            {heading && <StyledHeading variant="h2">{heading}</StyledHeading>}
            <StyledContent
                className="story-box-list"
                $centred={centred && data.length === 1}
            >
                {data.map(item => {
                    const size =
                        item?.size || (data.length < 3 ? 'large' : 'small');

                    return (
                        <StyledContentListItem
                            key={item.title}
                            className="story-box-item"
                            $size={size}
                        >
                            <StoryBox {...item} size={size} variant={variant} />
                        </StyledContentListItem>
                    );
                })}
            </StyledContent>
        </StyledWrapper>
    );
};
