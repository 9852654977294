import { type IncomingMessage } from 'http';
import qs from 'qs';

import {
    type ChallengeOutcomePayload3Ds1,
    type ChallengeOutcomePayload3Ds2,
    type EncodedPaymentResult,
    is3Dv1,
} from '../ChallengeOutcome.types';
import { type AppLogger } from '@tgg/common-types';
import { TggTraceCorrelationParameters } from '@tgg/util';

type NextRequest = IncomingMessage & {
    cookies: Partial<{
        [key: string]: string;
    }>;
};

export const processPaymentResult = async (
    request: NextRequest,
    logger: AppLogger,
    loggerMetaData: TggTraceCorrelationParameters,
): Promise<EncodedPaymentResult> => {
    const streamPromise = new Promise((resolve, _reject) => {
        let postBody = '';

        request.on('data', data => {
            // convert Buffer to string
            postBody += data.toString();
        });

        request.on('end', () => {
            const postData = qs.parse(postBody);
            logger.info('3DS - Received PaRes/Cres', loggerMetaData);
            resolve(postData);
        });
    });

    const bodyOfPostRequest = (await streamPromise) as
        | ChallengeOutcomePayload3Ds1
        | ChallengeOutcomePayload3Ds2;

    return {
        value: is3Dv1(bodyOfPostRequest)
            ? bodyOfPostRequest.PaRes
            : bodyOfPostRequest.cres,
        type: is3Dv1(bodyOfPostRequest) ? 'ThreeDSOne' : 'ThreeDSTwo',
    };
};
